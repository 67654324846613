@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  
}
::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
code {
  font-family: Inter;
}
.bg-dotted-line {
  background-image: linear-gradient(to bottom, #FFF 50%, transparent 0%);
  background-size: 1px 10px; /* Adjust the 10px value to increase or decrease the gap */
}
