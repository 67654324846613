.driver-list-table {
    table {
        th {
            // width: 150px;
        }

        .actions-col {
            box-shadow: -10px 0 10px -2px rgb(0, 0, 0);
        }

    }
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}